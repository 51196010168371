import React from "react";
import ReactHtmlParser from "html-react-parser";

import {
  TermsContainer,
  CenteredContainer
} from "./Terms.styled";

const terms = `
<h1 id="terms-and-conditions-of-betsmarterapp">Terms and Conditions of <strong>betsmarter.app</strong></h1>
<p>These Terms govern</p>
<ul>
<li>the use of betsmarter.app, and,</li>
<li>any other related Agreement or legal relationship with the Owner</li>
</ul>
<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
<p>The User must read this document carefully.</p>
<p>Betsmarter.app is provided by:</p>
<p>BetSmarter OÜ - Harju maakond, Tallinn, Lasnamäe linnaosa, Lõõtsa tn 5, 11415</p>
<p><strong>Owner contact email:</strong> <a href="mailto:&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;">&#x69;&#110;&#102;&#111;&#64;&#98;&#101;&#x74;&#115;&#109;&#x61;&#114;&#x74;&#101;&#114;&#x2e;&#97;&#112;&#x70;</a></p>
<h1 id="information-about-betsmarterapp"><strong>Information about betsmarter.app</strong></h1>
<ol>
<li>The service provides the User with information, which is compiled by analyzing data and odds of bookmakers.</li>
<li>The service is not a financial tool and does not provide services for managing funds from third parties.<strong>The service is not a bookmaker or a gamble website and does not organize or conduct gambling.</strong></li>
</ol>
<p>&quot;Betsmarter.app&quot; refers to</p>
<ul>
<li>this website, including its subdomains and any other website through which the Owner makes its Service available;</li>
<li>the Application Program Interfaces (API);</li>
</ul>
<h1 id="what-the-user-should-know-at-a-glance"><strong>What the User should know at a glance</strong></h1>
<ul>
<li>The Service/betsmarter.app is only intended for Consumers.</li>
<li><strong>Usage of betsmarter.app and the Service is age restricted</strong>: to access and use betsmarter.app and its Service the User must be an adult under applicable law.</li>
<li>The right of withdrawal only applies to European Consumers.</li>
</ul>
<hr>
<h1 id="terms-of-use"><strong>TERMS OF USE</strong></h1>
<p>Unless otherwise specified, the terms of use detailed in this section apply generally when using betsmarter.app.</p>
<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
<p>By using betsmarter.app, Users confirm to meet the following requirements:</p>
<ul>
<li>Users must qualify as Consumers;</li>
<li>Users must be recognized as adult by applicable law;</li>
</ul>
<h3 id="account-registration"><strong>Account registration</strong></h3>
<p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.</p>
<p>Failure to do so will cause unavailability of the Service.</p>
<p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by betsmarter.app.</p>
<p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.</p>
<p>Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
<h3 id="conditions-for-account-registration"><strong>Conditions for account registration</strong></h3>
<p>Registration of User accounts on betsmarter.app is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
<ul>
<li>Accounts registered by bots or any other automated methods are not permitted.</li>
<li>Unless otherwise specified, each User must register only one account.</li>
<li>Unless explicitly permitted, a User account may not be shared with other persons.</li>
</ul>
<h3 id="account-termination"><strong>Account termination</strong></h3>
<p>Users can terminate their account and stop using the Service at any time by doing the following:</p>
<ul>
<li>By directly contacting the Owner at the contact details provided in this document.</li>
</ul>
<h3 id="account-suspension-and-deletion"><strong>Account suspension and deletion</strong></h3>
<p>The Owner reserves the right to suspend or terminate the User&#39;s account at any time and without notice, at the Owner&#39;s sole discretion, in these cases:</p>
<ul>
<li>User has violated these Terms; and/or</li>
<li>User&#39;s access or use of betsmarter.app may cause injury to the Owner, other Users or third parties; and/or</li>
<li>the use of betsmarter.app by the User may cause violation of law or regulations; and/or</li>
<li>in case of an investigation by legal action or governmental involvement; and/or</li>
<li>the account or its use is deemed to be, at the Owner’s sole discretion inappropriate or offensive or in violation of these Terms.</li>
</ul>
<p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</p>
<p>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
<h3 id="content-on-betsmarterapp"><strong>Content on betsmarter.app</strong></h3>
<p>Unless where otherwise specified or clearly recognizable, all content available on betsmarter.app is owned or provided by the Owner or its licensors.</p>
<p>The Owner undertakes its utmost effort to ensure that the content provided on betsmarter.app infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.</p>
<p>In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
<h3 id="rights-regarding-content-on-betsmarterapp---all-rights-reserved"><strong>Rights regarding content on betsmarter.app - All rights reserved</strong></h3>
<p>The Owner holds and reserves all intellectual property rights for any such content.</p>
<p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
<p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on betsmarter.app, nor allow any third party to do so through the User or their device, even without the User&#39;s knowledge.</p>
<p>Where explicitly stated on betsmarter.app, the User may download, copy and/or share some content available through betsmarter.app for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
<p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
<h3 id="access-to-external-resources"><strong>Access to external resources</strong></h3>
<p>Through betsmarter.app Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
<h3 id="acceptable-use"><strong>Acceptable use</strong></h3>
<p>Betsmarter.app and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
<p>Users are solely responsible for making sure that their use of betsmarter.app and/or the Service violates no applicable law, regulations or third-party rights.</p>
<p>Therefore,<strong>the Owner reserves the right to take any appropriate measure to protect its legitimate interests including denying Users access to betsmarter.app or the Service, terminating contracts, reporting any misconduct performed through betsmarter.app or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users are suspected to be in violation of any laws, regulations, third-party rights and/or these Terms, including, but not limited to, by engaging in any of the following activities:</strong></p>
<h3 id="conduct-restrictions"><strong>Conduct restrictions</strong></h3>
<ul>
<li>pretending to fulfill any possible condition or requirements for accessing betsmarter.app and/or using the Services, such as for instance being adult according to law or qualifying as a Consumer;</li>
<li>concealing their identity or stealing someone else&#39;s identity or pretend to be or represent a third-party, if not allowed to do so by such third-party;</li>
<li>manipulating identifiers to disguise or otherwise conceal the origin of their messages or of the content posted;</li>
<li>defaming, abusing, harassing, using threatening practices, threatening or violating the legal rights of others in any other way;</li>
<li>promoting activity that may endanger the User’s life or the life of any other User or lead to physical harm. This includes but is not limited to suicide threats or instigations, intentional physical trauma, the use of illegal drugs, or excessive drinking. Under no circumstance is any User allowed to post any content promoting and/or encouraging and/or showing any self-destructive or violent behavior on betsmarter.app;</li>
<li>probing, scanning or testing the vulnerability of betsmarter.app, including the services or any network connected to the website, nor breaching the security or authentication measures on betsmarter.app, including the services or any network connected to betsmarter.app;</li>
<li>installing, embedding, uploading or otherwise incorporating any malware into or via betsmarter.app;</li>
<li>using betsmarter.app or the technical infrastructure in an abusive, excessive or otherwise inappropriate way (for example: for spamming purposes);</li>
<li>attempting to disrupt or tamper with the technical infrastructure in a manner that harms or places an undue burden on betsmarter.app or the Service;</li>
</ul>
<h3 id="scraping"><strong>Scraping</strong></h3>
<ul>
<li>adopting any automated process to extract, harvest or scrape information, data and/or content from betsmarter.app and all the digital properties thereto related unless where explicitly allowed to do so by the Owner;</li>
</ul>
<h3 id="content-restrictions"><strong>Content restrictions</strong></h3>
<ul>
<li>disseminating or publishing content that is unlawful, obscene, illegitimate, libelous or inappropriate;</li>
<li>publishing any content that promotes, either directly or indirectly, hate, racism, discrimination, pornography, violence;</li>
<li>disseminating or publishing any content that is false or may create unjustified alarm;</li>
<li>using betsmarter.app to publish, disseminate or otherwise provide content protected by intellectual property laws, including but not limited to patent, trademark or copyright law, unlawfully and without the legitimate right-holder’s consent;</li>
<li>using betsmarter.app to publish, disseminate or otherwise make available any other content which infringes on any third-party rights, including but not limited to state, military, trade or professional secrets and personal data;</li>
<li>publishing any content or carrying out any activity that disrupts, interrupts, harms, or otherwise violates the integrity of betsmarter.app or another User&#39;s experience or devices. Such activities include: spamming, distributing unauthorized advertisements, phishing, defrauding others, spreading malware or viruses etc.;</li>
</ul>
<h3 id="tell-a-friend"><strong>“Tell-a-friend”</strong></h3>
<p>Betsmarter.app gives Users the opportunity to receive advantages if, as a result of their recommendation, any new User purchases a Product offered on betsmarter.app.</p>
<p>In order to take advantage of this offer, Users may invite others to purchase the Products on betsmarter.app by sending them a tell-a-friend code provided by the Owner. Such codes can only be redeemed once.</p>
<p>If upon purchase of the Products on betsmarter.app any of the persons invited redeems a tell-a-friend code, the inviting User shall receive the advantage or benefit (such as: a price reduction, an additional service feature, an upgrade etc.) specified on betsmarter.app.</p>
<p>Tell-a-friend codes may be limited to specific Products among those offered on betsmarter.app.</p>
<p>The Owner reserves the right to end the offer at any time at its own discretion.</p>
<p>While no general limitation applies to the number of persons that can be invited, the amount of advantage or benefit that each inviting User can receive, may be limited.</p>
<h3 id="api-usage-terms"><strong>API usage terms</strong></h3>
<p>Users may access their data relating to betsmarter.app via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses betsmarter.app, is bound by these Terms and, in addition, by the following specific terms:</p>
<ul>
<li>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.</li>
</ul>
<h1 id="terms-and-conditions-of-sale"><strong>TERMS AND CONDITIONS OF SALE</strong></h1>
<h3 id="paid-products"><strong>Paid Products</strong></h3>
<p>Some of the Products provided on betsmarter.app, as part of the Service, are provided on the basis of payment.</p>
<p>The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of betsmarter.app.</p>
<p>To purchase Products, the User must register or log into betsmarter.app.</p>
<h3 id="product-description"><strong>Product description</strong></h3>
<p>Prices, descriptions or availability of Products are outlined in the respective sections of betsmarter.app and are subject to change without notice.</p>
<p>While Products on betsmarter.app are presented with the greatest accuracy technically possible, representation on betsmarter.app through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.</p>
<p>The characteristics of the chosen Product will be outlined during the purchasing process.</p>
<h3 id="purchasing-process"><strong>Purchasing process</strong></h3>
<p>Any steps taken from choosing a Product to order submission form part of the purchasing process.</p>
<p>The purchasing process includes these steps:</p>
<ul>
<li>Users must choose the desired Product and verify their purchase selection.</li>
<li>After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.</li>
</ul>
<h3 id="order-submission"><strong>Order submission</strong></h3>
<p>When the User submits an order, the following applies:</p>
<ul>
<li>The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.</li>
<li>In case the purchased Product requires an action from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.</li>
<li>Upon submission of the order, Users will receive a receipt confirming that the order has been received.</li>
</ul>
<p>All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.</p>
<h3 id="prices"><strong>Prices</strong></h3>
<p>Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.</p>
<p>Prices on betsmarter.app are displayed:</p>
<ul>
<li>including all applicable fees, taxes and costs.</li>
</ul>
<h3 id="methods-of-payment"><strong>Methods of payment</strong></h3>
<p>Information related to accepted payment methods are made available during the purchasing process.</p>
<p>Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of betsmarter.app.</p>
<p>All payments are independently processed through third-party services. Therefore, betsmarter.app does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.</p>
<p>If a payment through the available methods fails or is refused by the payment service provider, the Owner shall be under no obligation to fulfill the purchase order. If a payment fails or is refused, the Owner reserves the right to claim any related expenses or damages from the User.</p>
<h3 id="authorization-for-future-paypal-payment"><strong>Authorization for future PayPal payment</strong></h3>
<p>If Users authorize the PayPal feature which allows future purchases, betsmarter.app will store an identification code linked to the Users’ PayPal account. This will authorize betsmarter.app to automatically process payments for future purchases or recurring installments of past purchases.</p>
<p>This authorization can be revoked at any time, either by contacting the Owner or by changing the user settings offered by PayPal.</p>
<h3 id="retention-of-usage-rights"><strong>Retention of usage rights</strong></h3>
<p>Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.</p>
<h3 id="delivery"><strong>Delivery</strong></h3>
<h3 id="performance-of-services"><strong>Performance of services</strong></h3>
<p>The purchased service shall be performed or made available within the timeframe specified on betsmarter.app or as communicated before the order submission.</p>
<h3 id="contract-duration"><strong>Contract duration</strong></h3>
<h3 id="subscriptions"><strong>Subscriptions</strong></h3>
<p>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</p>
<h3 id="fixed-term-subscriptions"><strong>Fixed-term subscriptions</strong></h3>
<p>Paid fixed-term subscriptions start on the day the payment is received by the Owner and last for the subscription period chosen by the User or otherwise specified during the purchasing process.</p>
<p>Once the subscription period expires, the Product shall no longer be accessible.</p>
<h1 id="user-rights"><strong>User rights</strong></h1>
<h3 id="right-of-withdrawal"><strong>Right of withdrawal</strong></h3>
<p>Unless exceptions apply, the User may be eligible to withdraw from the contract within the period specified below (generally 14 days), for any reason and without justification. Users can learn more about the withdrawal conditions within this section.</p>
<h3 id="who-the-right-of-withdrawal-applies-to"><strong>Who the right of withdrawal applies to</strong></h3>
<p>Unless any applicable exception is mentioned below, Users who are European Consumers are granted a statutory cancellation right under EU rules, to withdraw from contracts entered into online (distance contracts) within the specified period applicable to their case, for any reason and without justification.</p>
<p>Users that do not fit this qualification, cannot benefit from the rights described in this section.</p>
<h3 id="exercising-the-right-of-withdrawal"><strong>Exercising the right of withdrawal</strong></h3>
<p>To exercise their right of withdrawal, Users must send to the Owner an unequivocal statement of their intention to withdraw from the contract.</p>
<p>To this end, Users may use the model withdrawal form available from within the “definitions” section of this document. Users are, however, free to express their intention to withdraw from the contract by making an unequivocal statement in any other suitable way. In order to meet the deadline within which they can exercise such right, Users must send the withdrawal notice before the withdrawal period expires.</p>
<p>When does the withdrawal period expire?</p>
<ul>
<li><strong>Regarding the purchase of a service</strong>, the withdrawal period expires 14 days after the day that the contract is entered into, unless the User has waived the withdrawal right.</li>
</ul>
<h3 id="effects-of-withdrawal"><strong>Effects of withdrawal</strong></h3>
<p>Users who correctly withdraw from a contract will be reimbursed by the Owner for all payments made to the Owner, including, if any, those covering the costs of delivery.</p>
<p>However, any additional costs resulting from the choice of a particular delivery method other than the least expensive type of standard delivery offered by the Owner, will not be reimbursed.</p>
<p>Such reimbursement shall be made without undue delay and, in any event, no later than 14 days from the day on which the Owner is informed of the User’s decision to withdraw from the contract. Unless otherwise agreed with the User, reimbursements will be made using the same means of payment as used to process the initial transaction. In any event, the User shall not incur any costs or fees as a result of such reimbursement.</p>
<h3 id="on-the-purchase-of-services"><strong>…on the purchase of services</strong></h3>
<p>Where a User exercises the right of withdrawal after having requested that the service be performed before the withdrawal period expires, the User shall pay to the Owner an amount which is in proportion to the part of service provided.</p>
<p>Such payment shall be calculated based on the fee contractually agreed upon, and be proportional to the part of service provided until the time the User withdraws, compared with the full coverage of the contract.</p>
<h1 id="liability-and-indemnification"><strong>Liability and indemnification</strong></h1>
<h3 id="indemnification"><strong>Indemnification</strong></h3>
<p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer&#39;s fees and costs ⁠— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
<h3 id="limitation-of-liability"><strong>Limitation of liability</strong></h3>
<p>Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>
<p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as betsmarter.app has been appropriately and correctly used by the User.</p>
<p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
<h1 id="common-provisions"><strong>Common provisions</strong></h1>
<h3 id="no-waiver"><strong>No Waiver</strong></h3>
<p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
<h3 id="service-interruption"><strong>Service interruption</strong></h3>
<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
<p>Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw Personal Data or information and will respect Users&#39; rights relating to continued product use and/or compensation, as provided for by applicable law.</p>
<p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events( infrastructural breakdowns or blackouts etc.).</p>
<h3 id="service-reselling"><strong>Service reselling</strong></h3>
<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of betsmarter.app and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
<h3 id="privacy-policy"><strong>Privacy policy</strong></h3>
<p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of betsmarter.app.</p>
<h3 id="intellectual-property-rights"><strong>Intellectual property rights</strong></h3>
<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to betsmarter.app are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
<p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with betsmarter.app are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
<h3 id="changes-to-these-terms"><strong>Changes to these Terms</strong></h3>
<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
<p>Such changes will only affect the relationship with the User from the date communicated to Users onwards.</p>
<p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.</p>
<p>The applicable previous version will govern the relationship prior to the User&#39;s acceptance. The User can obtain any previous version from the Owner.</p>
<p>If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.</p>
<h3 id="assignment-of-contract"><strong>Assignment of contract</strong></h3>
<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.</p>
<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
<h3 id="contacts"><strong>Contacts</strong></h3>
<p>All communications relating to the use of betsmarter.app must be sent using the contact information stated in this document.</p>
<h3 id="severability"><strong>Severability</strong></h3>
<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
<h3 id="us-users"><strong>US Users</strong></h3>
<p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.</p>
<h3 id="eu-users"><strong>EU Users</strong></h3>
<p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.</p>
<p>In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>
<p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
<h3 id="governing-law"><strong>Governing law</strong></h3>
<p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
<h3 id="prevalence-of-national-law"><strong>Prevalence of national law</strong></h3>
<p>However, regardless of the above, if the law of the country that the User is located in provides for a higher applicable consumer protection standard, such higher standards shall prevail.</p>
<h3 id="venue-of-jurisdiction"><strong>Venue of jurisdiction</strong></h3>
<p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
<h3 id="exception-for-consumers-in-europe"><strong>Exception for Consumers in Europe</strong></h3>
<p>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in the United Kingdom, Switzerland, Norway or Iceland.</p>
<h1 id="dispute-resolution"><strong>Dispute resolution</strong></h1>
<h3 id="amicable-dispute-resolution"><strong>Amicable dispute resolution</strong></h3>
<p>Users may bring any disputes to the Owner who will try to resolve them amicably.</p>
<p>While Users&#39; right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of betsmarter.app or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.</p>
<p>The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to the Owner’s email address specified in this document.</p>
<p>The Owner will process the complaint without undue delay and within 21 days of receiving it.</p>
<h3 id="online-dispute-resolution-for-consumers"><strong>Online dispute resolution for Consumers</strong></h3>
<p>The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving disputes related to and stemming from online sale and service contracts.</p>
<p>As a result, any European Consumer or Consumer based in Norway, Iceland, or Liechtenstein can use such platform for resolving disputes stemming from contracts which have been entered into online. The platform is <a href="http://ec.europa.eu/consumers/odr/">available at the following link</a>.</p>
<h2 id="definitions-and-legal-references"><strong>Definitions and legal references</strong></h2>
<p>Latest update: June 19, 2023</p>
`;

export const Terms = () => {

  return (
    <>
      <TermsContainer>
        <CenteredContainer>
          {ReactHtmlParser(terms)}
        </CenteredContainer>
      </TermsContainer>
    </>
  );
};
