import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useTranslation } from 'react-i18next';
import { FooterSection } from '../Home/FooterSection/FooterSection';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modal from "react-bootstrap/Modal"; // Add this import

import {
  ExtensionContainer,
  MainHeader,
  PointsContainer,
  PointsTitle,
  Points,
  AvailablePointsContainer,
  AvailablePointsTitle,
  AvailablePoints,
  Title,
  Row,
  HowItWorks,
  StatusBadge,
  PointsTable,
  PointsTableItem,
  PointsTableItemHeader,
  PointsTableItemText,
  LeftContainer,
  ReportsContainer,
  PointsControl, // Add this styled component
  PointsDisplay, // Add this styled component
  AnimationContainer, // Add this styled component
  PointsAnimation // Add this styled component
} from "./Rewards.styled";
import { NavBarMain } from "..";
import { useEvents, useUser } from "../../hooks";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { Table } from "react-bootstrap";

export const Rewards = ({ history }) => {
  const { getReports } = useEvents();
  const [reports, setReports] = useState(undefined);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false); // Add this state
  const [redeemPointsAmount, setRedeemPointsAmount] = useState(0); // Add this state
  const [couponCode, setCouponCode] = useState(undefined);
  const { redeemPoints } = useUser();

  const handleShow = () => {
    setRedeemPointsAmount(0); // Reset redeem points when showing modal
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);

  const handleRedeem = async () => {
    const days = Math.floor(redeemPointsAmount / 1000);
    // Add logic to redeem points here
    const response = await redeemPoints(redeemPointsAmount);
    setCouponCode(response.coupon);
    console.log(`Redeemed ${redeemPointsAmount} points for ${days} days`);
    // handleClose();
  };

  const incrementPoints = () => {
    const maxPoints = Math.floor(reports?.availablePoints / 1000) * 1000;
    setRedeemPointsAmount((prev) => Math.min(prev + 1000, maxPoints));
  };

  const decrementPoints = () => {
    setRedeemPointsAmount((prev) => Math.max(prev - 1000, 0));
  };

  useEffect(async () => {
    checkIfTokenStillValid(history);
    const response = await getReports();
    setReports(response);
  }, []);

  return (
    <>
      <ExtensionContainer>
        <NavBarMain currentPage="rewards" history={history}/>
        <MainHeader>
          <Title>{t('rewards')}</Title>
          <Row>
            <LeftContainer>
              <PointsContainer>
                <PointsTitle>{t('totalPoints')}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 200 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {t('howItWorksText')}
                        {t('reportTerms', { returnObjects: true }).map((term, index) => (
                          <li key={index}>{term}</li>
                        ))}
                      </Tooltip>
                    }
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
                      <i className="fa fa-question-circle"></i>
                    </div>
                  </OverlayTrigger>
                </PointsTitle>
                <Points style={{ color: '#B8DCEF' }}>{reports?.totalPoints}</Points>
                <AvailablePointsContainer>
                  <AvailablePointsTitle>{t('availablePoints')}</AvailablePointsTitle>
                  <AvailablePoints>{reports?.availablePoints}</AvailablePoints>
                </AvailablePointsContainer>
                  <Button onClick={handleShow}>
                  {t('exchangePoints')}
                </Button>
              </PointsContainer>
              <PointsContainer>
                <PointsTitle>{t('ranking')}</PointsTitle>
                <Points style={{ color: '#E9FD8C' }}>#1</Points>
              </PointsContainer>
            </LeftContainer>
            <PointsContainer>
              <PointsTitle>{t('howItWorks')}
              </PointsTitle>
              <PointsTable>
                <PointsTableItem>
                  <PointsTableItemHeader>+200</PointsTableItemHeader>
                  <PointsTableItemText>{t('pointsTable1')}
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 50, hide: 200 }}
                      overlay={<Tooltip id="button-tooltip-2">{t('pointsTable1Tooltip')}</Tooltip>}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
                        <i className="fa fa-question-circle"></i>
                      </div>
                    </OverlayTrigger>
                  </PointsTableItemText>
                </PointsTableItem>
                <PointsTableItem>
                  <PointsTableItemHeader>+100</PointsTableItemHeader>
                  <PointsTableItemText>{t('pointsTable2')}
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 50, hide: 200 }}
                      overlay={<Tooltip id="button-tooltip-2">{t('pointsTable2Tooltip')}</Tooltip>}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
                        <i className="fa fa-question-circle"></i>
                      </div>
                    </OverlayTrigger></PointsTableItemText>
                </PointsTableItem>
              </PointsTable>
            </PointsContainer>
          </Row>
        </MainHeader>
        <ReportsContainer>
          <Title>{t('reports')}</Title>
          <Table hover>
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('event')}</th>
                <th>{t('status')}</th>
                <th>{t('points')}</th>
              </tr>
            </thead>
            <tbody>
              {reports?.reports
              ?.sort((a, b) => new Date(b.addedOn) - new Date(a.addedOn))
              .map((report, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 ? '#111E12' : '#1d2d1d'}}>
                  <td>
                    {new Date(report.addedOn).toLocaleDateString()}
                  </td>
                  <td>
                    {report.event}
                  </td>
                  <td>
                    <StatusBadge status={report.status || 'PENDING'}>
                      {t(report.status || 'pending').toUpperCase()}
                    </StatusBadge>
                  </td>
                  <td>
                    {report.points > 0 ? `+${report.points}` : report.points || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ReportsContainer>
        <FooterSection />
      </ExtensionContainer>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('exchangePoints')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('exchangePointsDescription')}
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
              {t('redeemableDays', { days: Math.floor(redeemPointsAmount / 1000) })}
            </div>
            {couponCode && <p>{t('couponCode', { couponCode })}</p>}
            <PointsControl>
              <Button variant="secondary" onClick={decrementPoints}>-</Button>
              <PointsDisplay>{redeemPointsAmount}</PointsDisplay>
              <Button variant="secondary" onClick={incrementPoints}>+</Button>
            </PointsControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={handleRedeem} disabled={redeemPointsAmount < 1000}>
            {t('exchange')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
