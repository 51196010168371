import React from 'react';
import { useTranslation } from 'react-i18next';

import check from "../../../static/home/check.png";

import {
  PricingSectionContainer,
  ModeContainer,
  PricingContainer,
  Title,
  Subtitle,
  PricingCard,
  PlanTitle,
  PlanPrice,
  FeatureList,
  FeatureItem,
  ChoosePlanButton,
  CheckIcon,
  PlansContainer,
  PlanPricePerDay,
  FeaturesContainer,
  FeaturesListContainer,
  FeaturesTitle,
  PlanDays,
  PlanSale,
  PricingContainerFree,
  FeaturesContainerFree,
  FeaturesListContainerFree,
} from './PricingSection.style.js';

export const PricingSection = () => {
  const { t } = useTranslation();

  const plans = [
    {
      title: t('weekly'),
      price: 43.99,
      days: 7,
    },
    {
      title: t('monthly'),
      price: 159.99,
      days: 30,
    },
    {
      title: t('quarterly'),
      price: 414.99,
      days: 90,
    },
    {
      title: t('biannual'),
      price: 779.99,
      days: 180,
    },
  ];

  return (
    <PricingSectionContainer>
      <Title>Live</Title>
      <ModeContainer>
        <PricingContainer>
          <FeaturesContainer>
            <FeaturesTitle style={{ textAlign: 'center', color: '#E9FD8C' }}>Premium</FeaturesTitle>
            <FeaturesListContainer>
              <FeatureList>
                {t('featuresList1', { returnObjects: true }).map((feature, index) => (
                  <FeatureItem key={index}>
                    <CheckIcon src={check} alt="check icon" /> {feature}
                  </FeatureItem>
                ))}
                {t('featuresList2', { returnObjects: true }).map((feature, index) => (
                  <FeatureItem key={index}>
                    <CheckIcon src={check} alt="check icon" /> {feature}
                  </FeatureItem>
                ))}
              </FeatureList>
            </FeaturesListContainer>
            <ChoosePlanButton className="btn btn-lg" href="/register">{t('getStarted')}</ChoosePlanButton>
          </FeaturesContainer>
          <PlansContainer>
            {plans.map((plan, index) => (
              <PricingCard key={index}>
                <PlanTitle>{plan.title}</PlanTitle>
                <PlanDays>({plan.days} {t('days')})</PlanDays>
                {plan.sale && <PlanSale>{t('sale')}</PlanSale>}
                <PlanPrice>{plan.price}€</PlanPrice>
                <PlanPricePerDay>{(plan.price / plan.days).toFixed(2)}€/{t('day')}</PlanPricePerDay>
              </PricingCard>
            ))}
          </PlansContainer>
        </PricingContainer>
        <PricingContainerFree>
          <FeaturesContainerFree>
            <FeaturesTitle style={{ textAlign: 'center' }}>Free</FeaturesTitle>
            <FeaturesListContainerFree>
              <FeatureList>
                {t('featuresList1Free', { returnObjects: true }).map((feature, index) => (
                  <FeatureItem key={index}>
                    <i className="fa-regular fa-circle-xmark" style={{ fontSize: '18px' }} />{" "}
                    {feature}
                  </FeatureItem>
                ))}
                {t('featuresList2Free', { returnObjects: true }).map((feature, index) => (
                  <FeatureItem key={index}>
                    <i className="fa-regular fa-circle-xmark" style={{ fontSize: '18px' }} />{" "}
                    {feature}
                  </FeatureItem>
                ))}
              </FeatureList>
            </FeaturesListContainerFree>
            <ChoosePlanButton className="btn btn-lg" href="/register" style={{ width: '200px' }}>{t('getStarted')}</ChoosePlanButton>
          </FeaturesContainerFree>
        </PricingContainerFree>
      </ModeContainer>
    </PricingSectionContainer>
  );
};
