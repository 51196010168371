import React from "react";
import {
  NavbarBrand,
  NavbarBrandLink,
  NavbarLinksContainer,
  NavbarLinksList,
  StyledNavbar,
  CreateAccountButton,
  LogInButton,
  NavbarTogglerIcon,
  NavbarLinksListItem,
  NavbarLinksListMenu,
  NavbarLink,
  HomeButton
} from "./NavBarRegister.styled";
import { checkToken } from "../../repositories/utils";
import { useTranslation } from 'react-i18next';

export const NavBarRegister = ({ color, currentPage }) => {
  const { t } = useTranslation();

  return (
    <StyledNavbar className="navbar navbar-expand" color={color}>
      {!checkToken() && (
        <>
          <NavbarLinksContainer
            className=""
            id="navbarNav"
            justifyContent="flex-end"
          >
            <NavbarLinksList className="">
              <HomeButton className="btn btn-lg" href="/">{t('home')}</HomeButton>
              { currentPage !== "login" && <LogInButton className="btn btn-lg" href="/login">{t('login')}</LogInButton> }
              { currentPage !== "register" && <LogInButton className="btn btn-lg" href="/register">{t('signup')}</LogInButton> }
            </NavbarLinksList>
          </NavbarLinksContainer>
        </>
      )}
    </StyledNavbar>
  );
};
