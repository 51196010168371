import styled from "styled-components";
import clsx from "clsx";

export const StyledNavbar = styled.nav`
  position: absolute;
  width: 100%;
  margin-bottom: 20px;
  z-index: 100;
  ${(props) => (props.color ? `background-color: ${props.color}` : "")};

  @media only screen and (max-width: 400px) {
    .dropdownText > a {
      padding: 0.2rem 0.2rem;
    }

    .notificationItem {
      min-width: 300px;
    }
  }

  @media only screen and (max-width: 300px) {
    .notificationItem {
      min-width: 270px;
    }
  }

  @media only screen and (max-width: 500px) {
    .navbar-collapse {
      padding: 10px;
      border-radius: 5px;
      z-index: 100;
    }
  }
`;

export const NavbarBrand = styled.div`
  display: flex;
  align-items: center;
`;

export const NavbarBrandLink = styled.a``;

export const NavDropdownTitle = styled.a``;
export const NavbarTogglerIcon = styled.span`
  border: 3px solid gray;
`;

export const InboxIcon = styled.i`
  margin-right: 5px;
  font-size: 20px;
  vertical-align: bottom;
  // color: black;
`;

export const NavbarLinksContainer = styled.div`
  justify-content: ${(props) => props.justifyContent};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const NavbarLinksListMenu = styled.ul`
  gap: 10px;
`;

export const NavbarLinksList = styled.ul`
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 10px;
  margin-right: 30px;

  @media only screen and (min-width: 700px) {
    position: absolute;
    right: 10px;
    top: 12px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
    margin-right: 0px;
  }
`;

export const NavbarLinksListItem = styled.li.attrs((props) => ({
  className: clsx("nav-item", { active: props.active }),
}))`
  &.active {
    font-weight: bold;
  }
`;

export const NavbarLink = styled.a``;

export const LogInButton = styled.a`
  color: #fff;

  border: 3px solid #fff;

  :hover {
    background: #111E12;
  }
`;

export const HomeButton = styled.a`
  background: white ;
  color: #111E12;

  :hover {
    background: #111E12;
  }

  @media only screen and (max-width: 768px) {
    border: 3px solid white;
    }
`;
