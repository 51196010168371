import styled from "styled-components";
import Button from "react-bootstrap/Button";

export const TermsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  .text-muted {
    margin-bottom: 0px !important;
  }
`

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`